<template>
    <div class="default">
        <el-config-provider :locale="locale">
        <LayoutHeader></LayoutHeader>
            <slot></slot>
        <LayoutFooter></LayoutFooter>
        <el-backtop :bottom="50" :right="50" style="width:90px;height:60px;">
            <div class="go-top"></div>
        </el-backtop>
        </el-config-provider>
    </div>
</template>

<script>
  import lang from 'element-plus/lib/locale/lang/zh-cn'
  import { defineComponent } from 'vue';
  export default defineComponent({
    setup(){
      let locale = lang
      return { locale }
    }
  })
</script>
<style lang="less" scoped>
    .go-top{
        height: 100%;
        width: 100%;
        background-image: url('@/assets/images/icon-go-top.png');
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: var(--el-bg-color-overlay);
        box-shadow: var(--el-box-shadow-lighter);
        text-align: center;
        line-height: 60px;
        color: #1989fa;
        padding: 15px;
        border-radius: 10px;
      }
</style>