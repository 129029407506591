<template>
    <div class="footer">
        <div class="container">
            <div class="top">
                <div class="logo">
                    <img :src="(config.public.API_BASE+logo)" alt="">
                </div>
                <div class="share">
                    <div class="txt">-社交矩阵-</div>
                    <div class="image">
                        <client-only>
                        <el-popover
                            placement="top-start"
                            trigger="hover"
                            width="240"
                        >
                        
                            <template #reference>
                                <img src="@/assets/images/icon-weibo.png" alt="">
                            </template>
                            <template #default>
                                <img :src="config.public.API_BASE+footer_matrix_weibo_qrcode" alt="" width="200">
                                <!-- <qrcode-vue :value="`http://service.weibo.com/share/share.php?url=${config.public.MOBILE_SITE+$route.href}&sharesource=weibo&title=${site_name}&pic=${config.public.API_BASE+logo}`" :size="200" level="H" /> -->
                            </template>
                        </el-popover>
                        <el-popover
                            placement="top-start"
                            trigger="hover"
                            width="240"
                        >
                        
                            <template #reference>
                                <img src="@/assets/images/icon-wechat.png" alt="">
                            </template>
                            <template #default>
                                <img :src="config.public.API_BASE+footer_matrix_wechat_qrcode" alt="" width="200">
                            </template>
                        </el-popover>
                        <el-popover
                            placement="top-start"
                            trigger="hover"
                            width="240"
                        >
                        
                            <template #reference>
                                <img src="@/assets/images/icon-douyin.png" alt="">
                            </template>
                            <template #default>
                                <img :src="config.public.API_BASE+footer_matrix_douyin_qrcode" alt="" width="200">
                            </template>
                        </el-popover>
                    </client-only>
                    </div>
                </div>
                <div class="content">
                    <div class="nav">
                        <nuxt-link
                        v-for="(item, index) in otherList"
                        :key="index"
                        class="link"
                        :to="{path: item.type.code==='page'?item.url:(item.url+'/'+item.id), query:{cid:item.id,pid:item.parent_id,list_type:item.list_type,content_type:item.type.code}}"
                        >{{ item.name }}</nuxt-link
                    >
                    </div>
                </div>
                <div class="qr-code">
                    <div class="txt"><span>关注神木文旅</span><br><span>扫描二维码关注</span><br><span>官方微信公众号</span></div>
                    <img :src="config.public.API_BASE+footer_matrix_wechat_qrcode" alt="">
                </div>
            </div>

            <div class="bottom">
                <div class="txt">
                    <template v-if="tech">
                        <span>技术支持©{{tech}}</span>
                    </template>
                    <template v-if="site_name">
                        |<span>版权所有©{{site_name}} </span>
                    </template>
                    <template v-if="beian">
                        |<span v-html="beian"></span>
                    </template>
                    <template v-if="other_info">
                        |<span v-html="other_info"></span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref,onMounted } from 'vue'
    import QrcodeVue from 'qrcode.vue'

    const config = useRuntimeConfig()

    const navList = await getMenus()
  
    const otherList = navList.value.filter((item)=>item.parent_id==0 && item.is_index==0)

    const sitConfig = await getConfig()

    console.log(sitConfig.value)
    const {logo,beian,other_info,site_name,tech,wechat_qrcode,footer_slogan,footer_matrix_douyin_qrcode,footer_matrix_wechat_qrcode,footer_matrix_weibo_qrcode} = sitConfig.value

    onMounted(() => {
        window.localStorage.setItem('logo', logo)
        window.localStorage.setItem('footer_slogan', footer_slogan)
    })
</script>

<style lang="less" scoped>
.footer{
    background-color: #ffffff;
    min-height: 400px;
    padding:  100px 0;
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        font-size: 16px;
        .logo{
            img{
                width: 300px;
            }
        }
        .share{
            display: flex;
            flex-direction: column;
            .txt{
                padding-bottom: 15px;
                letter-spacing: 5px;
                font-size: 18px;
            }
            .image{
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width: auto;
                    height: 25px;
                }
            }
        }
        .content{
            display: flex;
            flex-direction: column;
            .nav{
                display: flex;
                justify-content: center;
                padding-bottom: 15px;
                font-size: 16px;
                a{
                    font-size: 18px;
                    padding: 0 10px;
                    color: #282728;
                    border-right: 1px solid #ddd;
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
            .txt{
                height: 25px;
                line-height: 25px;
                span{
                    margin: 0 20px;
                }
                text-align: center;
            }
        }
        .qr-code{
            display: flex;
            align-items: center;
            .txt{
                margin-right: 10px;
                span{
                    display: inline-block;
                    margin: 2px 0;
                    &:first-child{
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                }
            }
            
            img{
                width: 140px;
            }
        }
    }
    
    .bottom{
        text-align: center;
        font-size: 16px;
        color: #686568;
        span{
            margin: 0 20px;
        }
    }
    @media screen and (max-width: 600px) {
        .top{
            flex-direction: column;
            height: 400px;
        }
        .bottom{
            span{
                padding: 20px 0;
                display: block;
            }
        }
    }
}
</style>