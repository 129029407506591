<template>
    <div class="header">
        <Nav></Nav>
        <swiper
            :rewind="true"
            :autoplay="{ delay: 3500, disableOnInteraction: false }"
            :modules="modules"
            :loop="true"
            class="swiper"
            >
            <swiper-slide v-for="(item, index) in sliderData" :key="index">
                <img :src="(config.public.API_BASE+item.image)" :alt="item.title" :style="`width: 100%;height:calc(${route.path === '/'?'85vh':'65vh'});object-fit: cover;`">
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    import 'swiper/swiper.min.css'
    import "swiper/css/effect-fade";
    import {ref,reactive,watch} from 'vue'
    import { Autoplay,EffectFade } from "swiper";
    const modules = [Autoplay,EffectFade]
    const config = useRuntimeConfig()
    const route = useRoute()
    const cid = ref(route.path === '/'?0:route.query.cid)
    const pid = ref(route.path === '/'?0:route.query.pid)
   
    const sliderData = ref([])
    const getSliderData = async(cid)=>{
        const data = await getSlider({column_id:cid})
        sliderData.value = data.value.filter(item => item.category_code == 'top')
    }

    getSliderData(cid.value)

    if(sliderData.value.length && pid.value != 0){
        cid.value = pid.value
    }

    const {proxy} = getCurrentInstance()
    proxy.$bus.on('categoryChange',(params) => {
        cid.value = params.cid
        pid.value = params.pid
    })

    watch(cid,(newValue,oldValue) => {
        getSliderData(newValue)
    })
    
    watch(sliderData,async (newValue,oldValue) => {
        if(newValue.length == 0 && pid.value != 0){
            cid.value = pid.value
        }
    })
</script>

<style lang="less" scoped>
.header{
    width: 100%;
    overflow: hidden;
    min-height: 230px;
    background-image: url('@/assets/images/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    .swiper {
        z-index: 0;
    }
}

</style>