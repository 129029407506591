<template>
    <div class="nav">
        <ul class="nav-list container">
            <li class="logo">
                <nuxt-link @click.stop="stateChange(0,0)" :to="'/'">
                    <img :src="config.public.API_BASE+logo" alt="">
                </nuxt-link>
            </li>
            <li v-for="(item, index) in navList" :key="index">
                <template v-if="item.children.length">
                <ul class="sub-list">
                    <li v-for="(itemChild, indexChild) in item.children" :key="indexChild" @click.stop="stateChange(itemChild.id,item.id)">
                        <a v-if="item.is_external_link==1" :href="item.url" target="_blank" class="sub-tit">
                            {{ itemChild.name }}
                        </a>
                        <nuxt-link v-else-if="itemChild.is_show != 0" :to="{path: itemChild.type.code==='page'?itemChild.url:(itemChild.url+'/'+itemChild.id), query:{cid:itemChild.id,pid:itemChild.parent_id,list_type:itemChild.list_type,content_type:itemChild.type.code,slider_type:itemChild.slider_type}}" class="sub-tit"
                            >{{ itemChild.name }}
                        </nuxt-link>
                    </li>
                </ul>
                </template>
                <template v-if="item.is_index==1">
                    <nuxt-link  @click.stop="stateChange(0,0)" :to="{path: item.url}" :class="item.id==navState?'title is_active':'title'">{{ item.name }}</nuxt-link>
                </template>
                <template v-else-if="item.is_external_link==1">
                    <a :href="item.url" target="_blank" :class="item.id==navState?'title is_active':'title'">
                        {{ item.name }}
                    </a>
                </template>
                <template v-else>
                    <nuxt-link @click.stop="stateChange(item.id,item.id)" :to="{path: (item.type.code==='page'?item.url:(item.url+'/'+item.id)), query:{cid:item.id,pid:item.parent_id,list_type:item.list_type,content_type:item.type.code,slider_type:item.slider_type}}" :class="item.id==navState?'title is_active':'title'">{{ item.name }}</nuxt-link>
                </template>
            </li>
            <li class="search">
            <el-input
                    v-model="keywords"
                    class="w-50 m-2"
                    placeholder="输入搜索关键字"
                    v-on:keyup.enter="searchBtn"
                    clearable
                >
            
                <template #append>
                    <el-button :icon="Search" @click="searchBtn"/>
                </template>
            </el-input>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { ElMessage } from 'element-plus';
    import { Search } from '@element-plus/icons-vue'
    import { ref, onMounted } from 'vue'
    const config = useRuntimeConfig()
    const route = useRoute()
    const navState = ref(route.query.cid)
    
    const categoryState = ref(0)
    const keywords = ref(null)
    const {proxy} = getCurrentInstance()
    const state = reactive({
        cid: 0
        ,pid: 0
    })
    watch(state,(newValue,oldValue) => {
        console.log('navState.value:'+navState)
        proxy.$bus.emit('categoryChange',newValue)
    })

    const stateChange = (cid,pid) => {
        navState.value = pid
        categoryState.value = cid
        state.cid = cid
        state.pid = pid
    }

    const logo = ref(null)
    const navList = await getMenus()

    console.log('navlist')
    console.log(navList.value)

    onMounted(() => {
        logo.value = window.localStorage.getItem('logo')
        window.localStorage.setItem('menus',JSON.stringify(navList.value))
    })

    const searchBtn = () => {
        if(!keywords.value){
            ElMessage({
                message:'请输入关键词',
                type:'warning'
            })
        }
        const router = useRouter()
        router.push({name:'search',
        query:{
            keywords:keywords.value
        }})
    }
</script>

<style lang="less" scoped>
.fun_nav_list(@colorDefault: #fff, @colorHeight: #fff, @colorRed:#C30E23) {
    .logo{
        display: flex;
        align-items: center;
        flex-grow: 3!important;
        img{
            width: 230px;
            transform: translateY(-100px);
            filter: drop-shadow(0 100px #ffffff);
        }
    }
    .search{
        display: flex;
        align-items: center;
    }
    .nav-list {
        display: flex;
        height: 100%;
        box-sizing: border-box;
        & > li {
            flex-grow: 1;
            padding: 0 20px;
            height: 100%;
            position: relative;
            z-index: 999;

            .title {
                color: @colorDefault;
                white-space: nowrap;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                position: relative;
                &:after {
                    background-color: #fff;
                    bottom: 0px;
                    content: "";
                    display: block;
                    height: 3px;
                    left: 80%;
                    opacity: 0;
                    position: absolute;
                    right: 80%;
                    -webkit-transition: all .5s;
                    -o-transition: all .5s;
                    -ms-transition: all .5s;
                    transition: all .5s;
                }

                // &.nuxt-link-exact-active.nuxt-link-active
                &:hover, &.is_active {
                    &::after {
                        left: 2%;
                        right: 2%;
                        opacity: 1;
                        -webkit-transition: all .5s;
                        -o-transition: all .5s;
                        -ms-transition: all .5s;
                        transition: all .5s;
                    }
                }
            }

            &:hover {
                .sub-list {
                    display: flex;
                }
            }

            .sub-list {
                position: absolute;
                width: 100%;
                left: 0;
                top: 99%;
                flex-direction: column;
                padding: 10px 0;
                display: none;

                // display: flex;
                li {
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.5);

                    .sub-tit {
                        display: block;
                        width: 100%;
                        color: #fff;
                        font-size: 18px;
                        text-align: center;
                        line-height: 50px;
                        text-decoration: none;
                        position: relative;
                        &:after {
                            background-color: @colorHeight;
                            bottom: 0px;
                            content: "";
                            display: block;
                            height: 1px;
                            left: 80%;
                            opacity: 0;
                            position: absolute;
                            right: 80%;
                            -webkit-transition: all .5s;
                            -o-transition: all .5s;
                            -ms-transition: all .5s;
                            transition: all .5s;
                        }
                        //  &.nuxt-link-exact-active.nuxt-link-active
                        &:hover {
                        color: @colorHeight;
                        &:after {
                                left: 2%;
                                right: 2%;
                                opacity: 1;
                                -webkit-transition: all .5s;
                                -o-transition: all .5s;
                                -ms-transition: all .5s;
                                transition: all .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.nav {
    box-sizing: border-box;
    height: 90px;
    width: 100%;
    background: linear-gradient(180deg, #3c3c3c30, transparent);
    position: absolute;
    z-index: 99;
    .fun_nav_list();
}
</style>